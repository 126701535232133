import { CopyIcon, PlainIcon } from "assets/icons";
import { useUser } from "context/UserContext";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import style from "./index.module.scss";
import { Card } from "antd";
import { Header } from "components/Layout/Header/Header";
import useCommonHook from "hooks/useCommonHook";

export const Introduce = () => {
  const { copyToClipboard } = useCommonHook();
  // useIntl template

  const intl = useIntl();
  const t = useCallback(
    (id) => {
      return intl.formatMessage({ id });
    },
    [intl],
  );
  const { user } = useUser();

  return (
    <>
      <Header backgroundColor="secondary" title={t("introduction")} />
      <div className={style["introduce"]}>
        <div className={style["introduce_card"]}>  
          <div>
            <div className={style["introduce_card_images"]}>
              <Card
                bordered={false}
                style={{
                  width: "100%",
                  borderRadius: 12,
                }}
              >
                <div className={style["introduce_card_qr"]}>
                  {user && user.referQRCode && (
                    <img src={user?.referQRCode} alt="" />
                  )}
                </div>
                <div>
                  <div>
                    <div className={style["introduce_card_title"]}>
                      {t("unique_link")}
                    </div>
                    <div
                      style={{
                        backgroundColor: "var(--background_color_active)",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        borderRadius: "5px",
                      }}
                      className="mt-2 d-flex justify-content-between align-items-center"
                    >
                      <div className={style["introduce_card_text"]}>
                        {user?.referLink}
                      </div>
                      <div
                        onClick={() => {
                          copyToClipboard(user?.referLink || "");
                        }}
                      >
                        <CopyIcon />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className={style["introduce_card_title"]}>
                      {t("invite_code")}
                    </div>
                    <div
                      style={{
                        backgroundColor: "var(--background_color_active)",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        borderRadius: "5px",
                      }}
                      className="mt-2 d-flex justify-content-between align-items-center"
                    >
                      <div className={style["introduce_card_text"]}>
                        {user?.referCode}
                      </div>
                      <div
                        onClick={() => {
                          copyToClipboard(user?.referCode || "");
                        }}
                      >
                        <CopyIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className={style["introduce_card_bugi"]}>
              <div
                style={{
                  width: "100%",
                  borderRadius: 4,
                  border: "1px solid #D3ADF7",
                  marginBottom: "50px",
                  background: "#F9F0FF",
                }}
                className={style["introduce_card_bugi_tera"]}
              >
                <div className={style["introduce_card_bugi_tera_note"]}>
                  <div
                    className={style["introduce_card_bugi_tera_note_icon"]}
                  >
                    <PlainIcon />
                  </div>
                  <div
                    className={style["introduce_card_bugi_tera_note_content"]}
                  >
                    <div
                      className={style["introduce_card_bugi_tera_content"]}
                    >
                      {t("note_one")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
