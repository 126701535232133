import React from "react";
import "./UserInfoBasic.scss";
import { useUser } from "context/UserContext";
import { IconCrownLine } from "assets/icons/iconBasic";

export default function UserInfoBasic({
  className = "",
  iconHeight = 32,
  iconWidth = 32,
  fontSize = "var(--font_size_text_small)",
}) {
  const { user } = useUser();

  return (
    <div
      className={`UserInfoBasic ${className}`}
      style={{ fontSize: fontSize }}
    >
      <img
        src={user?.userAvatar}
        alt=""
        className="UserInfoBasic_logo"
        style={{
          minHeight: iconHeight,
          maxHeight: iconHeight,
          minWidth: iconWidth,
          maxWidth: iconWidth,
        }}
      />
      <div className="UserInfoBasic_detail">
        {/* <div className="UserInfoBasic_detail_level">
          <IconCrownLine />
          <span>{user.appUserMembershipTitle || "Level 0"}</span>
        </div> */}
        {user?.companyName && (
          <p className="UserInfoBasic_detail_name">
            {user?.companyName}
          </p>
        )}
      </div>
    </div>
  );
}
